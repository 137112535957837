import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Container,
  CircularProgress
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { adminSignIn, auth } from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const AdminLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Get the page they were trying to visit
  const from = location.state?.from?.pathname || '/admin';

  // If already logged in and verified as admin, redirect
  useEffect(() => {
    if (user) {
      navigate(from);
    }
  }, [user, navigate, from]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      await adminSignIn(credentials.email, credentials.password);
      // AdminGuard will handle the redirection after verifying admin status
    } catch (err) {
      console.error('Admin login error:', err);
      setError(
        err instanceof Error 
          ? err.message 
          : 'Failed to sign in. Please check your credentials.'
      );
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 3
        }}>
          <AdminPanelSettingsIcon 
            color="primary" 
            sx={{ fontSize: 48, mb: 2 }} 
          />
          <Typography variant="h4" component="h1" gutterBottom>
            Admin Login
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Access the YardFinder Administrator Dashboard
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={credentials.email}
            onChange={handleChange}
            margin="normal"
            required
            disabled={loading}
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            margin="normal"
            required
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Sign In'
            )}
          </Button>
        </form>

        {process.env.NODE_ENV === 'development' && (
          <Typography 
            variant="caption" 
            color="text.secondary" 
            align="center" 
            sx={{ 
              display: 'block', 
              mt: 2 
            }}
          >
            WARNING: UNAUTHORIZED ATTEMPTS TO ACCESS THIS WEB CONSOLE ARE PUNISHABLE BY LAW AND ARE SUBJECT TO RESULT IN CRIMINAL PROSECUTION UNDER APPLICABLE STATUTES.
            
            . .  ALL ACCESS ATTEMPTS ARE MONITORED AND LOGGED.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default AdminLogin;
