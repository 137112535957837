import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
  Chip,
  Divider
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { UserSale } from '../types/user';
import ShareButtons from '../components/social/ShareButtons';
import { QRCodeCanvas } from 'qrcode.react';

const YardSaleConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sale = location.state?.sale as UserSale;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const qrRef = useRef<HTMLDivElement>(null);

  if (!sale) {
    navigate('/create-sale');
    return null;
  }

  const formatDate = (dateStr: string) => {
    return new Date(dateStr).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (timeStr: string) => {
    return new Date(`2000-01-01T${timeStr}`).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  const downloadQRCode = () => {
    if (!qrRef.current) return;
    
    const canvas = qrRef.current.querySelector('canvas');
    if (!canvas) return;

    // Create a temporary link
    const link = document.createElement('a');
    link.download = `${sale.title.toLowerCase().replace(/\s+/g, '-')}-qr.png`;
    link.href = canvas.toDataURL('image/png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Generate the URL for the sale details page
  const saleUrl = `${window.location.origin}/sale/${sale.id}`;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
          <Typography variant="h4" gutterBottom>
            Your Yard Sale Has Been Created!
          </Typography>
          <Typography color="text.secondary">
            Your sale has been successfully listed and is now visible to others.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Sale Details
            </Typography>
            <Typography variant="h5">{sale.title}</Typography>
            <Typography color="text.secondary" paragraph>
              {sale.description}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Date & Time
            </Typography>
            <Typography>
              {formatDate(sale.date)}
            </Typography>
            <Typography color="text.secondary">
              {formatTime(sale.time.start)} - {formatTime(sale.time.end)}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Location
            </Typography>
            <Typography>{sale.location}</Typography>
          </Box>

          {sale.categories.length > 0 && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Categories
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {sale.categories.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>
          )}

          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1" gutterBottom>
              Share Your Sale
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={() => setShareDialogOpen(true)}
              >
                Share
              </Button>
              <Button
                variant="outlined"
                startIcon={<QrCodeIcon />}
                onClick={downloadQRCode}
              >
                Download QR Code
              </Button>
            </Stack>
            <ShareButtons
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              title={`Check out my yard sale on YardFinder! - ${sale.title}`}
              url={saleUrl}
            />
            <Box 
              ref={qrRef} 
              sx={{ 
                mt: 2, 
                p: 2, 
                bgcolor: 'background.paper',
                borderRadius: 1,
                display: 'inline-flex',
                boxShadow: 1
              }}
            >
              <QRCodeCanvas
                value={saleUrl}
                size={128}
                level="H"
                includeMargin
              />
            </Box>
            <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
              Save this QR Code and include it in your Yard Sale flyers! 
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => navigate(`/sale/${sale.id}`)}
            >
              View Sale Page
            </Button>
            <Button
              variant="contained"
              startIcon={<ShareIcon />}
              onClick={() => navigate('/profile')}
            >
              Go to My Sales
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export default YardSaleConfirmationPage;
