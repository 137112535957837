import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import HomePage from './pages/HomePage';
import MapPage from './pages/MapPage';
import SearchResultsPage from './pages/SearchResultsPage';
import YardSaleDetailsPage from './pages/YardSaleDetailsPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ProfilePage from './pages/ProfilePage';
import CreateSalePage from './pages/CreateSalePage';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminLogin from './pages/admin/AdminLogin';
import SubscriptionPage from './pages/SubscriptionPage';
import Layout from './components/Layout/Layout';
import AdminGuard from './components/admin/AdminGuard';
import YardSaleConfirmationPage from './pages/YardSaleConfirmationPage';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/sale/:id" element={<YardSaleDetailsPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/create-sale" element={<CreateSalePage />} />
            <Route path="/sale-confirmation" element={<YardSaleConfirmationPage />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route 
              path="/admin/*" 
              element={
                <AdminGuard>
                  <AdminDashboard />
                </AdminGuard>
              } 
            />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
